<template>
  <div class="zrhj-content">
    <div class="lsrwInfor center">
      <bread-crumb v-if="isShowBread"></bread-crumb>
      <div class="title">
        <p>{{ zrhjName }}</p>
      </div>
      <div class="con-details">
        <div v-for="(item, i)  of dataTab" v-if="dataTab.length>0" class="item-lists"
             v-bind:class="[item.showmore? 'icon-height' : 'icon-unheight']">
          <div :class="'title-box title-box'+(Math.round(Math.random()*2+1))">
            <a>{{ item.mc }}</a>
          </div>
          <div class="card-box">
            <div class="card-lists">
              <div v-for="(list, index) of item.data" :key="index" :class="{active:nowIndex === index}" class="item-card"
                   @click="select(list)">
                <div class="head-pic">
                  <img :src="list.ydlj"/>
                </div>
                <div class="text-name">
                  <el-tooltip :content="list.title" :disabled="list.title.length>15?false:true" class="item"
                              effect="light" placement="top">
                    <a>{{ list.title }}</a>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="btn-box">
              <i v-if="item.data.length>5" class="icon"
                 v-bind:class="[item.showmore? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
                 @click="showmore(item)"></i>
            </div>
          </div>
        </div>
        <not-found v-if="dataTab.length === 0"></not-found>
      </div>
    </div>
  </div>

</template>
<script>
import breadCrumb from '@/components/common/breadCrumb';
import {getTabDataGroupByClass} from "../../api/homeService";
import notFound from "../../components/notFound";

export default {
  components: {
    breadCrumb,notFound
  },
  props: {
    tabId: {
      type: String,
      default: () => ''
    },
    zrhjName:{
      type: String,
      default: () => '自然环境'
    },
    zrzyDyId: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      isshow: false,
      isshow2: false,
      isshow3: false,
      nowIndex: 0,
      nowIndex2: 0,
      nowIndex3: 0,
      dataTab: [],
      isShowBread:false,
    }
  },
  created() {
    this.getDyxxById();
  },
  mounted() {

  },
  watch: {
    'tabId': {
      handler(val) {
        this.getDyxxById();
      }
    }
  },
  methods: {
    showmore(item) {
      item.showmore = !item.showmore
    },
    select(val) {
      this.$router.push({
        name: 'zrhjDetail',
        query: {
          id: val.sjid,
          tabId: this.$route.query.tabId,
          zrzyDyId: this.$route.query.zrzyDyId
        }
      })
    },
    select2(index) {
      this.nowIndex2 = index;
      this.$router.push({
        name: "zrhjDetail",
      });
    },
    select3(index) {
      this.nowIndex3 = index;
      this.$router.push({
        name: "zrhjDetail",
      });
    },

    getDyxxById() {
      const dy = {'zrzyid': this.$route.query.zrzyDyId, 'ftabid': this.tabId}
      getTabDataGroupByClass(dy).then(res => {
        if (res.code === '0') {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].showmore = true;
          }
          this.dataTab = res.data;
        }
      })
    },
  }
}
</script>

<style lang="less">
.lsrwInfor {
  padding-bottom: 16px;

  .title {
    padding: 17px 0px 20px 0px;

    p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }

  .con-details {
    width: 100%;

    .item-lists {
      width: 100%;
      // height: 240px;
      // height: auto;
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      border: 1px solid #CFDEE6;

      .title-box {
        width: 100px;
        // height:100%;

        display: flex;
        align-items: center;
        justify-content: center;

        a {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FEFEFE;
          line-height: 36px;
          // -webkit-writing-mode: vertical-rl;
          // writing-mode: vertical-rl;
          letter-spacing: 5px;
          width: 20px;
          display: inline-block;
          word-break: break-all;
        }
      }

      .title-box1 {
        background: url(../../assets/images/kpzs/bg-top.png) no-repeat left top,
        url(../../assets/images/kpzs/bg-bottom.png) no-repeat right bottom, #E69900;
      }

      .title-box2 {
        background: url(../../assets/images/kpzs/bg-top.png) no-repeat left top,
        url(../../assets/images/kpzs/bg-bottom.png) no-repeat right bottom, #00B377;
      }

      .title-box3 {
        background: url(../../assets/images/kpzs/bg-top.png) no-repeat left top,
        url(../../assets/images/kpzs/bg-bottom.png) no-repeat right bottom, #00AACC;
      }

      .card-box {
        width: 1100px;
        height: 100%;
        padding: 30px 50px 0px 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .card-lists {
          width: 100%;
          // height:170px;
          height: auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .item-card {
            width: 140px;
            // height: 170px;
            margin: 5px 30px 32px 30px;
            // background: #CFDEE6;
            cursor: pointer;

            .head-pic, img {
              width: 140px;
              height: 140px;
              border-radius: 50%;
            }

            .text-name {
              width: 100%;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 0px;

              a {
                display: block;
                height: 36px;
                text-align: center;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 18px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }

            }
          }

          .active, .item-card:hover {
            .head-pic {
              box-shadow: 0px 6px 20px 0px #ADCAD9;
            }

            .text-name {
              a {
                color: #009AE5;
              }
            }
          }
        }

        .btn-box {
          width: 100%;
          height: 20px;
          padding: 5px 0px;
          text-align: center;
          line-height: 20px;

          i {
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }

    .icon-height {
      height: auto;
      overflow: initial;
    }

    .icon-unheight {
      height: 260px;
      overflow: hidden;

      .card-lists {
        height: 210px !important;
      }
    }
  }
}

.el-tooltip__popper.is-light {
  border: 1px solid #CFDEE6 !important;
}
</style>

